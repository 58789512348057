import video1 from '../videos/video1.mp4'
import video2 from '../videos/video2.mp4'
import video3 from '../videos/video3.mp4'

const dataSlider = [
  {
    id: 1,
    video: video1
  },
  {
    id: 2,
    video: video2
  },
  {
    id: 3,
    video: video3
  },
];

export default dataSlider;